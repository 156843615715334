.remove-blank-space-above-modal {
  margin-top: -40px;
}

.holidaySelector {
  margin-left: 30%;
}

.holidaySelectorLabel {
  font-size: 14px;
}

.holidayCalendarFormSummary {
  align-self: flex-start;
  margin-left: 10px;
  margin-top: 25px;
}

.holidayCalendarFormSummaryTitle {
  font-size: 14px;
  font-weight: 600;
}

.holidayCalendarFormSummarySubTitles {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.5;
}

.holidayFormAdd {
  font-size: 14px;
  margin-left: -5px;
}
