/* Overlay for the modal */
.delete-confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal box */
  .delete-confirmation-box {
    width: 450px;
    height: 189px;
    padding: 20px 30px;
    background: #f7f7f7;
    border: 2px solid #c8c9cb;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.25);
  }
  
  /* Icon alignment */
  .delete-confirmation-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .delete-confirmation-icon-box svg {
    width: 40px;
    height: 40px;
  }
  
  /* Text styles */
  .delete-confirmation-title {
    width: 100px;
    height: 39px;
    gap: 0px;
    font-size: 26px;
    font-weight: 400;
    line-height: 39px;
    text-align: left;
    color: #001565;
  }
  
  .delete-confirmation-message {
    font-size: 16px;
    color: #323233;
    width: 163px;
    height: 27px;
    gap: 0px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  /* Buttons */
  .cancel-button,
  .delete-button {
    height: 36px;
    font-size: 14px;
    padding: 0 20px;
    border-radius: 5px;
  }
  
  .delete-button {
    background-color: #32439a !important;
    color: #ffffff !important;
    border: none;
  }
  
  .delete-button:hover {
    background-color: #28357f !important;
  }
  
  .cancel-button {
    border: 1px solid #b1b1b1 !important;
    color: #323233 !important;
  }
  
  .cancel-button:hover {
    background-color: #f1f1f1 !important;
  }
  