.search-input-stack {
  background-color: #f7f7f7;
  padding: 10px;
}

.search-input-group {
  background-color: #f5eeee;
  border: 1px solid #b4b3b3;
  padding: 5px 0;
}

.input-search {
  width: 100%;
  padding: 8px 10px;
}

.input-search .mantine-MultiSelect-input {
  border: 1px solid #71c0ee;
}

.input-search .mantine-MultiSelect-dropdown {
  overflow-y: auto;
}

.mantine-MultiSelect-rightSection {
  width: 50px;
}

.search-input-icon-wrapper {
  margin: auto;
  margin-right: 10px;
  padding: 5px 5px 5px 6px;
  border-radius: 4px;
  border: 1px solid #c5c1c1;
}

.search-input-icon {
  margin: auto;
}

.selected-item-badge {
  background-color: #e9f1ff;
  color: #757373;
  border-radius: 5px;
  min-height: 28px;
  font-size: 14px;
  margin-right: 10px;
  margin-top: -3px;
  text-transform: none;
}

.selected-item-badge-close {
  cursor: pointer;
  margin-left: 8px;
  font-size: 10px;
}
