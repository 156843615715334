.kpiSection:not(:first-child) {
  margin-left: 36px; /* 10px + 25px extra */
}

.kpiSection {
  margin-bottom: 30px;
  margin-top: 10px;
  margin-left: 10px;
}

.kpiActivity {
  /* width: 272.2px; */
  height: 116px;
  gap: 12px;
  border-radius: 13px;
  border: 1px 0px 0px 0px;
  border-color: #be8dde;
  box-shadow: 0px 4px 17px 0px #00000033;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fcf8ff;
}

.kpiActivityTitle {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #a171ba;
}
.kpiActivityValue {
  font-family: Plus Jakarta Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #644077;
}

.kpiActivitySelected {
  /* width: 272.2px; */
  height: 116px;
  gap: 12px;
  border-radius: 13px;
  border: 1px 0px 0px 0px;
  border-color: #be8dde;
  box-shadow: 0px 4px 17px 0px #00000033;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #644077;
}

.kpiActivityTitleSelected {
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #fcf8ff;
}
.kpiActivityValueSelected {
  font-family: Plus Jakarta Sans;
  font-size: 34px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #fcf8ff;
}
