.tradeEntryCreationPage {
  /* padding starts with 0px because the title has padding top already */
  gap: 20px;
}

.tradeEntrySection {
  background: #f5f5f5;
}

.tradeEntrySectionTitle {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: white;
  background-color: #95a4fd;
  border-radius: 8px 8px 0px 0px;
  padding: 4px 30px;
  width: 100%;
}

.dataEntryLabelValueWithDivider {
  width: 100%;
  height: 40px;
  gap: 25px;
  display: flex;
}

.dataEntryLabel {
  flex: 0 1 auto;
  height: 40px;
  padding: 10px 0px 10px 0px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f036d;
}

.dataEntryInput {
  flex: 1 1 0%;
  height: 40px;
  padding: 10px;
  gap: 10px;
  border: 1px solid #71c0ee;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #757373;
  background: #ffffff;
}

.dataEntryLabelND {
  flex: 0 1 auto;
  height: 40px;
  padding: 10px 0px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3f036d;
  border: 1px solid #71c0ee;
  background: #ffffff;
}

.dataEntryInputND {
  flex: 1 1 0%;
  height: 40px;
  padding: 10px;
  gap: 10px;
  border: 1px solid #71c0ee;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  text-decoration-skip-ink: none;
  color: #757373;
  background: #ffffff;
}

.tradeEntryCounterpartiesSection {
  min-width: 49%;
  gap: 16px;
  /* height: 258px; */
  /* padding: 20px 0px; */
}

.tradeTableScroll {
  max-height: 240px;
  overflow-y: auto;
  border: 1px solid #71c0ee;
}

.tradeEntryCounterpartiesStack {
  padding-left: 20px;
}

.tradeEntryDealSectionStack {
  border: 1px solid #b4b3b3;
  background: #ede6e6;
  padding: 20px;
  gap: 20px;
}

.singleRowInputGroup {
  padding: 20px;
  gap: 5px;
}

.tradeEntryInputGroup {
  padding: 20px 20px 20px 30px;
  gap: 20px;
}

.tradeTableContainer {
  border: 1px solid #c5c1c1;
  padding: 20px;
}

.tradeTable thead {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 48px;
}

.tradeSearchResult {
  color: red;
}

.tradeTable thead tr {
  background-color: #f0eeee;
}

.tradeTable tbody tr {
  height: 40px;
  cursor: pointer;
  border-bottom: 1px solid #cac9c9;
}

.tradeTable th {
  padding: 12px 10px !important;
  color: #784595 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.tradeTable {
  width: 100%;
}

.tradeTable td {
  padding: 12px 10px !important;
  color: #476db9;
  font-weight: 400;
  font-size: 14px !important;
  text-align: left;
}

.tradeEntryCounterpartiesTitleSection {
  width: 100%;
  /* height: 32px; */
  padding: 20px 0px 45px 0px;
  border-bottom: 1px solid #B4B3B3;
}

.tradeEntryCounterpartiesTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  color: #476DB9;
}

.tradeEntryCounterpartiesSearch {
  width: 38px;
  height: 32px;
  padding: 5px 8px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #c5c1c1;
  background: #ffffff;
}

.tradeEntryRadioButtons .mantine-Radio-label {
  font-size: 18px;
  font-weight: 400;
  color: #333334;
}

.tradeEntrySelectEntityLeft {
  width: 900px !important;
}

.tradeEntrySelectEntityRight {
  width: 900px !important;
  right: 0 !important;
  left: auto !important;
}

.mantine-Popover-dropdown {
  padding: 0px !important;
}
.tradeEntryRadioButtons .mantine-Radio-radio {
  background: #ffffff;
  border: 1px solid #3f036d;
}

.tradeEntryRadioButtons .mantine-Radio-icon {
  color: #3f036d;
}

.tradeEntryCounterpartiesSearchIcon {
  width: 20px !important;
  height: 20px !important;
}

.tradeEntryCounterpartiesNameSection {
  width: 100%;
  height: 40px;
  gap: 0px;
}

.tradeEntryCounterpartiesName {
  min-width: 25%;
  height: 42px;
  padding: 10px 25px 10px 25px;
  gap: 25px;
  border: 1px solid #71c0ee;
  border-width: 1px 0px 1px 1px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: left;
  color: #3f036d;
}

.tradeEntryCounterpartiesSubSections {
  height: 40px;
  gap: 10px;
}

.tradeEntryCounterpartiesEntry {
  width: 50%;
  height: 40px;
  gap: 5px;
}

.tradeEntryLabelInputSmall {
  width: 16%;
  height: 40px;
  gap: 0px;
}

.tradeEntryLabelInputMedium {
  width: 33%;
  height: 40px;
  gap: 0px;
}

.tradeEntryTradeRow {
  height: 40px;
  gap: 10px;
  margin-top: 10px;
}

.tradeEntryTable {
  border-collapse: collapse;
  width: 100%; /* Optional: Ensure the table spans its container */
  border: 1px solid #71c0ee;
}

.tradeEntryTable th {
  box-sizing: border-box;
  height: 48px;
  background: #f0eeee;
  border-bottom: 1px solid #cac9c9;
  text-align: left;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  padding-left: 5px;
  font-weight: 400;
  line-height: 19px;
  color: #3f036d;
}

.tradeEntryTable .tradeEntryTdBold {
  box-sizing: border-box;
  height: 40px;
  color: #454d7e;
  border-bottom: 1px solid #cac9c9;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  background-color: #ffffff; /* Ensure text is visible on the dark background */
}

.tradeEntryTable .tradeEntryTdInput {
  height: 40px;
  color: #757373;
  border: 1px solid #71c0ee;
  padding-left: 0px;
  font-size: 14px;
  line-height: 19px;
  font-style: italic;
  font-weight: 700;
  text-align: left;
  background-color: #ffffff; /* Ensure text is visible on the dark background */
}

.tradeEntryTable td {
  box-sizing: border-box;
  height: 40px;
  padding-left: 5px;
  color: #454d7e;
  border-bottom: 1px solid #cac9c9;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  background-color: #ffffff; /* Ensure text is visible on the dark background */
}

.tradeEntryTermsTradeGroup {
  gap: 10px;
}

.tradeEntrySubmissionSection {
  width: 100%;
  height: 33px;
  gap: 10px;
  margin-top: 20px;
}

.tradeEntrySubmissionCancel {
  width: 50%;
  height: 100%;
  padding: 6px 30px 6px 30px;
  gap: 10px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #b1b1b1;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #3d3d3d;
  text-align: center;
}
.tradeEntrySubmissionCancel:hover {
  background-color: #ffffff;
}

.tradeEntrySubmissionCreate {
  width: 50%;
  height: 100%;
  padding: 6px 30px 6px 30px;
  gap: 10px;
  border-radius: 5px;
  background: #32439a;
  border: 1px solid #32439a;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
  text-align: center;
}
.tradeEntrySubmissionCreate:hover {
  background-color: #32439a;
}
