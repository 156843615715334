.hover-card-wi-container {
  background-color: white;
  width: 100%;
  z-index: 500;
}

.hover-card-wi-content {
  padding: 15px;
  flex-direction: column;
}

.hover-card-wi-group {
  padding: 5px 0;
}

.hover-card-wi-text {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Plus Jakarta Sans';
}

.bold {
  font-weight: 800;
  color: #32439a;
}

.text-15 {
  font-size: 15px;
}
