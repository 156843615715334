.hover-card {
  padding: 3px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #95a4fd;
  border: none;
}

.hover-card-target {
  text-align: center;
}

.hover-card-title {
  width: 100%;
  padding: 8px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px 8px 0px 0px;
}

.hover-card-alert-text {
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-weight: 500;
  color: #2d3748;
  padding: 15px;
  width: 100%;
  background-color: white;
  z-index: 500;
}

.hover-card-button-container {
  font-family: 'Plus Jakarta Sans';
  color: #2d3748;
  padding: 0 0 15px 15px;
  width: 100%;
  background-color: white;
  z-index: 500;
}

.hover-card-button {
  background-color: #32439a;
  color: white;
  border-radius: 5px;
}
