.ionicon {
  font-size: 9px !important;
}

.nav-bar {
  flex: 1;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  /* responsive */

  height: 80px;
  max-width: 1200px;

  padding: 0 24px;
  margin: 0;
}

.nav-bar h2 {
  font-family: var(--font-primary);
  margin-left: 25px;
}

.nav-bar__tabs {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-bar__tab {
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-right: 24px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.nav-bar__tab:last-child {
  margin-right: 0;
}

.nav-bar__tab--active {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--indigo);

  /* responsive */

  text-decoration-thickness: 4px;
  text-underline-offset: 8px;
}

.nav-bar__tab:hover {
  color: var(--black);
}

.nav-bar__buttons {
  display: flex;
  margin-left: 24px;
}

@media only screen and (max-width: 640px) and (hover: none) {
  .mobile-nav-bar__tab:hover {
    color: var(--white);
  }

  .mobile-nav-bar__tab--active:hover {
    color: var(--white);
  }
}

@media only screen and (max-width: 640px) {
  .nav-bar__tab {
    font-size: 1.5rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__buttons {
    margin-left: 24px;
  }
}

@media only screen and (max-width: 340px) {
  .nav-bar__tab {
    font-size: 1.3rem;
  }

  .nav-bar {
    height: 6.4rem;

    padding: 0.8rem 1.6rem;
  }

  .nav-bar__tab--active {
    text-decoration-line: none;
    color: var(--pink);
  }

  .nav-bar__buttons {
    margin-left: 24px;
  }
}

.notifications-icon-default {
  margin-left: 10;
  cursor: pointer;
}

.notifications-icon-active {
  margin-left: 10;
  cursor: pointer;
  filter: invert(22%) sepia(51%) saturate(4792%) hue-rotate(338deg)
    brightness(89%) contrast(86%);
}

.notification-icon-count {
  position: relative;
  left: 23.5px;
  bottom: 1px;
  z-index: 1;
  color: white;
  font-size: 1.5em;
  width: 10px;
  margin-left: -10px;
  pointer-events: none;
  font-weight: 600;
  text-align: center;
}

.notification-menu-items {
  padding: 4px 0px 4px 0px;
  border-radius: 8px;
  border: 1px solid black;
  height: 66px !important;
  background-color: white;
  margin-bottom: 5px;
}

.notification-menu-items-info {
  margin-left: 45px;
}

.notification-menu-items-title {
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #374151;
}

.notification-menu-dropdown {
  background: transparent !important;
  border: 0px !important;
  box-shadow: none;
}

.notification-menu-items-details {
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #374151;
}

.notification-menu-items-time {
  font-family: 'Plus Jakarta Sans';
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #374151;
}

.notification-menu-items-icon {
  position: fixed;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.notification-menu-items-remove {
  position: fixed;
  width: 12px;
  height: 12px;
  margin-left: 210px;
  margin-top: 8px;
}
