.segmented-root {
  z-index: 1;
  background-color: transparent;
  position: relative;
  bottom: -4px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-top: -4px;
  gap: 40px;
}

.segmented-label {
  font-family: 'Plus Jakarta Sans';
  font-size: 22px !important;
  font-weight: 600;
  color: #784595;
  background-color: #ffffff;
  padding-bottom: 4px;
}

.segmented-indicator {
  display: none;
}

.segmented-control-active .mantine-SegmentedControl-label {
  color: #e8006e;
  border-bottom: 4px solid #be8dde;
}

.segmented-control-active {
  background-color: #ffffff;
}

.segmented-control {
  border-style: none !important;
}

.segmented-control:hover .mantine-SegmentedControl-label {
  color: #e8006e;
}
