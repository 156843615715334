.accordionLight {
  background-color: #a4cafe;
  color: black;
  font-family: 'Plus Jakarta Sans';
}
.accordionLight > .mantine-Accordion-chevron {
  color: black;
}
.accordionLight:active {
  background-color: #a4cafe;
}
.accordionLight:hover {
  background-color: #a4cafe;
}
.accordionLight[data-active] {
  background-color: #a4cafe;
}

.accordionMain {
  background-color: #32429a;
  color: white;
  font-family: 'Plus Jakarta Sans';
}
.accordionMain > .mantine-Accordion-chevron {
  color: white;
}
.accordionMain:active {
  background-color: #32429a;
}
.accordionMain:hover {
  background-color: #32429a;
}
.accordionMain[data-active] {
  background-color: #32429a;
}

.accordionMainTitle {
  color: white;
  font-weight: 600;
}

.accordionFull {
  border-radius: 8px 8px 0px 0px !important;
  border: none;
}

.accordionFull .mantine-Accordion-control {
  background-color: #95a4fd;
  font-family: 'Plus Jakarta Sans';
  text-align: left;
  color: #ffffff;
  border-radius: 8px 8px 0px 0px !important;
  padding: 0px 40px 0px 40px;
}

.accordionFull .mantine-Accordion-control[data-active] {
  background-color: #95a4fd; /* Active state background */
}

.accordionFull .mantine-Accordion-control:not([data-active]) {
  background-color: #95a4fd; /* Collapsed state background */
}

.accordionFull .mantine-Accordion-control:hover,
.accordionFull .mantine-Accordion-control[data-active]:hover,
.accordionFull .mantine-Accordion-control:not([data-active]):hover {
  background-color: #95a4fd; /* Maintain background on hover */
}

.accordionFull .accordionFullTitle {
  font-size: 24px;
  font-weight: 600;
}
