.loading {
    position: relative;
    align-content: center;
    height: 150px;
    width: 150px;
    animation: spin 2s infinite linear;
    border-radius: 50%;
    background: conic-gradient(from 0deg, #3F036D, #9B016C, #F7006B);
  }
  
.loading::after {
    width: 140px;
    height: 140px;
    position: absolute;
    display: block;
    border-radius: 80%;
    top: 5px;
    left: 5px;
    border-radius: 80%;
    background-color:white;
    content: '';
}

.loaderLogo {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
}

.loaderWrapper {
    position: relative;
    width: 150px;
    height: 150px;
}
  
.loaderPage {
    width: 100%;
    height: 434px;
    border: solid 1px #71C0EE;
}
  
@keyframes loading {
    to {
      transform: rotate(360deg);
    }
}