.contact-table {
    width: 100%;
    border: 1px solid #71C0EE; /* Main table border */
    border-collapse: collapse; /* Ensures no overlapping of borders */
  }
  
  .contact-table th {
    padding: 12px 10px;
    gap: 12px;
    border-width: 0 0 1px 0;
    border-style: solid;
    background-color: #F0EEEE;
    color: #413E3E;
    height: 35px;
    text-align: left; /* Optional: Adjust alignment as needed */
  }
  
  .contact-table td {
    padding: 24px 0;
    gap: 0;
    border-width: 0 0 1px 0;
    border-style: solid;
    background-color: #FFFFFF;
    color: #413E3E;
    height: 35px;
  }
  
  /* Remove bottom border from the last row */
  .contact-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  /* Column-specific styles */
  .contact-table td:first-child,
  .contact-table th:first-child {
    width: 50px;
  }

  .contact-table td:first-child{
    padding-left: 20px;
  }
  
  .contact-table td:not(:first-child),
  .contact-table th:not(:first-child) {
    width: calc((100% - 50px) / 4); /* Distributes remaining space among 4 columns */
  }
  