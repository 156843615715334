.rightpedal-features {
  /* responsive */

  padding: 3.2rem 6.4rem;
}

.rightpedal-features__title {
  color: var(--white);
  font-weight: 600;
  text-align: center;

  /* responsive */

  margin-top: 0;
  margin-bottom: 4.8rem;

  font-size: 3.2rem;
  line-height: 3.2rem;
}

.rightpedal-features__grid {
  display: grid;

  /* responsive */

  grid-template-columns: 1fr 1fr;
  column-gap: 6.4rem;
  row-gap: 6.4rem;
}

.rightpedal-feature {
  display: block;

  background-color: var(--white);

  color: var(--black);

  cursor: pointer;

  transition: all 0.3s ease-in-out 0s;

  /* responsive */

  padding: 4.8rem;

  border-radius: 1.6rem;
}

.rightpedal-feature:hover {
  transform: scale(1.03);
}

.rightpedal-feature__headline {
  display: flex;
  align-items: center;
  margin-top: 0;
  color: var(--black);
  font-weight: 600;
  letter-spacing: -0.05rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.rightpedal-feature__icon {
  /* responsive */
  display: initial;
  margin-right: 1.6rem;
}

.rightpedal-feature:hover {
  text-decoration: underline solid 2px var(--black);
  text-underline-offset: 2px;
  transition: all 0.3s ease-in-out 0s;
}

.rightpedal-feature__description {
  margin: 25px 50px 25px 50px;
  color: black;
  font-weight: 500;

  opacity: 0.7;

  /* responsive */

  font-size: 1.6rem;
  line-height: 2.4rem;
}

@media only screen and (max-width: 900px) {
  .rightpedal-features__grid {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .rightpedal-feature {
    padding: 3.2rem;
    border-radius: 0.8rem;
  }

  a .rightpedal-feature__headline {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .rightpedal-feature__icon {
    height: 3.6rem;
    display: initial;
    margin-right: 1.6rem;
  }

  .rightpedal-feature__description {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .rightpedal-features {
    padding: 1.6rem;
  }

  .rightpedal-features__title {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 2.4rem;
  }

  .rightpedal-features__grid {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }

  .rightpedal-feature {
    padding: 3.2rem;
    border-radius: 0.8rem;
  }

  h3.rightpedal-feature__headline {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .rightpedal-feature__icon {
    height: 3.6rem;
    display: initial;
    margin-right: 1.6rem;
  }

  .rightpedal-feature__description {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}
