.status-badge {
  width: 90px;
  height: 25px;
  background: #ffffff;
  border: 1px solid;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Inter', sans-serif;
}

.status-badge.active {
  color: #287a26;
  border-color: #287a26;
}

.status-badge.inactive {
  color: #cb3232;
  border-color: #cb3232;
}

.delete-button {
  background-color: #e8006e;
  color: #fff;
  border-radius: 5px;
  padding: 6px 30px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 33px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff337a;
}

.edit-button {
  width: 70px;
  height: 33px;
  background-color: #32439a;
  color: #fff;
  border-radius: 5px;
  padding: 6px 30px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #4356c0;
}

.search-input {
  width: 312px;
  position: relative;
}

.search-input .mantine-TextInput-input {
  padding-left: 10px !important;
  border-radius: 8px;
  height: 32px;
  font-size: 14px;
  color: #757373;
}

.search-input .mantine-TextInput-input::placeholder {
  color: #757373;
  font-style: italic;
}

.search-input .mantine-TextInput-icon {
  right: 8px;
  left: auto;
  color: #000000;
  position: absolute;
}

.rows-per-page-group {
  display: flex;
  align-items: center;
  gap: 5px;
}

.rows-per-page-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #484646;
  margin-right: 5px;
}

.rows-per-page-select {
  width: 70px;
  border-radius: 5px;
  font-size: 14px;
}

.pagination-controls .mantine-Pagination-control {
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-radius: 50%;
  border-color: #cfcdcd;
}

.pagination-controls .mantine-Pagination-control[data-active] {
  background-color: #32439a;
  color: #fff;
}

.export-button {
  width: 150px;
  height: 35px;
  background-color: #32439a;
  color: '#FFFFFF';
  border-radius: 5px;
}

.entity-name {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 21.78px;
  color: #784595;
}

.paper {
  width: 100%;
  grid-column: auto;
  z-index: auto;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  background-color: #f3f3f3;
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  box-shadow: none;
}

.paper-expanded {
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-top: 1px solid #855fa1;
  border-left: 1px solid #855fa1;
  border-right: 1px solid #855fa1;
}

.status-switch-label {
  color: #b1b1b1; /* Default inactive color */
  padding: 2px 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  transition: color 0.3s ease;
}

.status-switch-label.active {
  color: #784595;
}

.status-switch-track {
  width: 45px;
  height: 22px;
  border-radius: 40px;
  transition: background-color 0.3s ease !important;
  background-color: #e0e0e0 !important;
}

.status-switch-track.active {
  background-color: #3f036d !important;
}

.status-switch-thumb {
  width: 20px;
  height: 20px;
  background-color: #fff;
  transition: left 0.3s ease !important;
}

.status-switch-thumb.active {
  left: calc(100% - 1.125rem - 1rem) !important;
}

.status-switch-thumb.inactive {
  left: 0.125rem !important;
}

.entity-name-input {
  flex: 1;
}

.entity-name-input .mantine-TextInput-input {
  border-radius: 5px;
  border: 1px solid #71c0ee;
  font-size: 16px;
  color: #484646;
  padding: 10px;
}

.entity-name-input .mantine-TextInput-input::placeholder {
  color: #757373;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
}

.form-actions-group {
  display: flex;
  gap: 16px;
}

.form-actions-button {
  width: 250px;
  height: 33px;
  border-radius: 5px;
  padding: 6px 30px;
  font-weight: 600;
  font-size: 15px;
}

.form-actions-button.cancel {
  border-color: #b1b1b1;
  color: #413e3e;
  background-color: #ffffff;
  border: 1px solid #b1b1b1;
}

.form-actions-button.save {
  background-color: #32439a;
  color: #ffffff;
}

.role-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 4px;
}

.role-text {
  -webkit-text-decoration: none;
  text-decoration: none;
  line-height: 19px;
  font-weight: 600;
  color: #413e3e;
}

.custom-switch-track {
  width: 45px;
  height: 22px;
  background-color: #e0e0e0 !important;
  border-radius: 40px;
  transition: background-color 0.3s ease !important;
}

.custom-switch-track.active {
  background-color: #3f036d !important;
}

.custom-switch-thumb {
  width: 20px;
  height: 20px;
  background-color: #fff;
  transition: left 0.3s ease !important;
}

.custom-switch-thumb.active {
  left: calc(100% - 1.125rem - 1rem) !important;
}

.custom-switch-thumb.inactive {
  left: 0.125rem !important;
}

.roles-container {
  border: 1px solid #71c0ee;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.roles-title, .roles-subtitle {
  -webkit-text-decoration: none;
  text-decoration: none;
  line-height: 19px;
  font-weight: 700;
  color: #413e3e;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 19px;
  border-bottom: 1px solid #e3e0e0;
  padding-bottom: 4px;
}

.roles-subtitle {
  padding-left: 4px;
}

.entity-form-paper {
  margin-bottom: 20px;
  background-color: #f3f3f3;
  padding: 10px 40px;
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #855fa1;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  height: 42px;
  padding: 0 10px;
  border: 1px solid #71c0ee;
  border-radius: 5px;
  background-color: #ffffff;
  color: #3f036d;
}

.custom-checkbox .mantine-Checkbox-input {
  width: 16px;
  height: 16px;
  border: 2px solid #3f036d;
}

.custom-checkbox .mantine-Checkbox-input:checked {
  background-color: #3f036d;
  color: #ffffff;
}

.custom-checkbox .mantine-Checkbox-body {
  flex-direction: row-reverse;
  gap: 10px;
}

.custom-checkbox .mantine-Checkbox-label {
  font-size: 16px;
  font-weight: 400;
  color: #3f036d;
}

.custom-checkbox .mantine-Checkbox-icon {
  padding-top: 3px;
  padding-left: 3px;
  width: 12px;
  height: 12px;
}

.kyc-type-select {
  width: 120px;
  border-radius: 5px;
}

.kyc-type-select .mantine-Select-input {
  border-radius: 5px;
  border: 1px solid #71c0ee;
  color: #3f036d;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

.kyc-type-select .mantine-Select-input::placeholder {
  color: #3f036d;
  font-size: 14px;
  font-weight: 400;
}

.kyc-type-select-right-section {
  padding-right: 10px;
  padding-top: 5px;
}

.kyc-type-select-right-section svg {
  fill: none;
  stroke: #3f036d;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
