.sectionTitle {
  width: 100%;
  height: 52px;
  padding: 10px 40px 10px 40px;
  gap: 10px;
  border-radius: 8px 8px 0px 0px;
  background-color: #95a4fd;
  color: #ffffff;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
.sectionTitleSM {
  width: 100%;
  height: 32px;
  padding: 0px 30px 0px 30px;
  gap: 10px;
  border-radius: 8px 8px 0px 0px;
  background-color: #95a4fd;
  color: #ffffff;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.sectionBody {
  background-color: #f7f7f7;
  padding: 0px 0px 15px 0px;
}

.sectionRemoveGap {
  gap: 0px;
}

.sectionDetailBody {
  width: 100%;
  /* height: 281px; */
  padding: 0px 40px 0px 40px;
  gap: 18px;
  background-color: #f7f7f7;
}

.sectionDetailTradeBody {
  width: 100%;
  padding: 0px 10px 0px 10px;
  gap: 18px;
  background-color: #f7f7f7;
}

.sectionDetailHeader {
  width: 100%;
  height: 32px;
  gap: 10px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #2c2b2b;
}

.sectionDetailHeaderDrawer {
  width: 100%;
  height: 32px;
  padding: 12px 0px 12px 0px;
  gap: 10px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #2c2b2b;
}

.sectionDetailBodyDrawer {
  width: 100%;
  gap: 18px;
  background-color: #f7f7f7;
}

.sectionDetailBlueHeader {
  width: 100%;
  gap: 10px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #ffffff;
  background-color: #95a4fd;
  padding: 5px 30px;
  border-radius: 8px 8px 0px 0px;
}

.noHeader {
  padding-top: 12px;
}

.sectionObject {
  gap: 2px;
  margin-bottom: 2px;
}

.sectionItemTitle {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #32439a;
  line-height: 32px;
}

.sectionItemTitleManual {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #32439a;
  line-height: 32px;
}

.sectionItemTitleClickable {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #32439a;
  cursor: pointer;
  line-height: 32px;
  text-decoration: underline;
  text-decoration-thickness: 10%;
}

.sectionItemTitleClickable:hover {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #1e93d2;
  cursor: pointer;
  line-height: 32px;
  text-decoration: underline;
  text-decoration-thickness: 10%;
}

.sectionItemTitleSM {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #32439a;
  line-height: 32px;
}

.sectionItemTitleSMClickable {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #32439a;
  cursor: pointer;
  line-height: 32px;
  text-decoration: underline;
  text-decoration-thickness: 10%;
}

.sectionItemTitleSMClickable:hover {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #1e93d2;
  cursor: pointer;
  line-height: 32px;
  text-decoration: underline;
  text-decoration-thickness: 10%;
}

.sectionItem {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #393939;
  line-height: 32px;
}

.sectionItemClickabe {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #32439a;
  cursor: pointer;
  line-height: 32px;
}

.sectionItemSM {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #393939;
  line-height: 32px;
}

.sectionItemSMClickabe {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #32439a;
  cursor: pointer;
  line-height: 32px;
}

.tableMargin {
  margin-bottom: 32px;
}

.tablePadding {
  padding: 40px;
}
